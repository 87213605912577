body{
    // background: #f5efd2;
    background: #f2efdd;
}
:root{
    --bg:linear-gradient(90deg, rgba(3,0,103,1) 0%, rgba(0,55,247,1) 50%, rgba(3,0,103,1) 100%);
    --bg2: linear-gradient(-45deg, #17284e, #770737, #041b4d, #133381);
}
.btn-warning {
  color: #fff !important;
  background-color: #f4511e !important;
  border-color: #f4511e !important;
}
  .menuOffcanvas{
    max-width: 80%;
  }

.headTop{
    background:var(--bg2);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
   
    ul{
        margin: 0;
        padding: 0;
        display: inline-flex;
        gap: 15px;
        li{
            display: inline-block;
            color: #fff;
            font-size: 14px;
            padding: 5px 0;
            a{
                color: #fff;
                display: inline-block;
            }
        }
    }
}
.menuContact{


  ul{
    margin: 0;
    padding: 0;
    li{
      display: block;
    }
  }
}
a{text-decoration: none;}
.navbar-brand{
    img{
        height: 75px;
        transition: all .5s;
    }
   
}
.dropdown-menu{
  border: 0 !important;
  padding-top: 0;
  box-shadow:hsla(220, 8%, 30%, 0.294) 0 -1px 8px;
  border-radius: 0;
  .dropdown-item{
    border-bottom: #f3eeff 1px dashed;
    &:last-child{
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
  &:last-child{
    padding-bottom: 0;
  }
}
.stickyStyle{
    background: #fff !important;
    transition: all .5s;
    width: 100%;
    position: fixed;
    .navbar-brand{
        img{
            height: 40px;
        }
    }
}
.navbar-light {
    .nav-link{border-radius: 4px !important; text-transform: uppercase; color: #041b4d !important; font-weight: 500; padding-left: 1.5rem !important;  padding-right: 1.5rem !important;}
    .active{
        background: linear-gradient(90deg, rgb(0, 140, 255) 0%, rgba(0,55,247,1) 50%, rgb(65, 59, 255) 100%);
        color: #fff !important;
    }
}
.BannerBox{
    .carousel-item{
        img{
            // height: calc(100vh - 150px) !important;
            width: 100%;
            object-fit: cover;
        }
    }
}

.FistServicesBx{
    margin: 2.5rem 0; 
    padding-top: 1.1rem;
    .sBox{
        // background: #fff;
        // // border-radius: 0.8rem;
        // padding: 1rem;
        // text-align: center;
        z-index: 1;
        overflow: hidden;
        padding: 40px 10px;
        position: relative;
        border-radius: 130px;
        transition: 0.3s all ease-in-out;
        box-shadow: 0px 11px 54px 0px rgba(13, 0, 30, 0.2);
        text-align: center;
        width: 280px;
        min-height: 420px;
        background-color: #fff;
      &:before{
        z-index: -2;
        bottom: -70px;
        left: -120px;
        background-image: url(../images/fs2.png);

        opacity: 0;
  width: 400px;
  z-index: -1;
  content: "";
  height: 360px;
  position: absolute;
  background-repeat: no-repeat;
  transition: 0.5s all ease-in-out;
      }
      &:after{
        bottom: 0;
  left: -130px;
  z-index: -1;
      background-image: url(../images/fs2.png);
      transition-delay: 0.3s;

      opacity: 0;
  width: 400px;
  z-index: -1;
  content: "";
  height: 360px;
  position: absolute;
  background-repeat: no-repeat;
  transition: 0.5s all ease-in-out;
        }
        &:hover{
          background-color: #d1c2ff;
          &:before{
            left: -95px;
            opacity: 1;
          }
          &::after{
            opacity: 1;
            left: -90px;
          }
        }
    }
}

// react
.reactBoxMain{
  background: #fff;
  padding: 2rem 0;
  // .reactInntext{
  //   font-size: 18px;
  // }
  .reactStyle{
    margin: 0;
    text-align: justify;
    h2{
      font-weight: 700;
      position: relative;
      // margin-bottom: 25px;
      color: #0e2258;
      font-size: 36px;
      text-align: start;
    }
    .reactBox{
      h5{
        color: #0e2258;
        // font-weight: 700;
      }
      p{
        font-size: 14px;
      }
    }
  }
  .reactArrow{
    i{
      margin-top: 4px;
      display: block;
      font-size: 24px;
    }
  }

}
// .aboutBx{
//   background: #7042e1; color: #fff;
//   position: relative;
//   padding: 3rem 0;
//   margin: 3rem 0;
// }
// .aboutBx:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: -5px;
//   background-color: #7754f4;
//   width: 100%;
//   height: 100px;
//   z-index: -1;
//   -moz-transform: rotate(1.5deg) skewX(1deg);
//   -webkit-transform: rotate(1.5deg) skewX(1deg);
//   -o-transform: rotate(1.5deg) skewX(1deg);
//   -ms-transform: rotate(1.5deg) skewX(1deg);
//   transform: rotate(1.5deg) skewX(1deg);
// }
// .aboutBx:after {
//   content: "";
//   background-color: #62b815;
//   position: absolute;
//   top: calc(100% - 100px);
//   right: -0px;
//   width: 100%;
//   height: 100px;
//   z-index: -1;
//   -moz-transform: rotate(1.5deg) skewX(1deg);
//   -webkit-transform: rotate(1.5deg) skewX(1deg);
//   -o-transform: rotate(1.5deg) skewX(1deg);
//   -ms-transform: rotate(1.5deg) skewX(1deg);
//   transform: rotate(1.5deg) skewX(1deg);
// }
// .bg2nd{
//   content: "";
//   background-color: #62b815;
//   position: absolute;
//   top: 0;
//   right: -0px;
//   width: 100%;
//   height: 100px;
//   z-index: -1;
//   -moz-transform: rotate(-1.5deg) skewX(-1deg);
//   -webkit-transform: rotate(-1.5deg) skewX(-1deg);
//   -o-transform: rotate(-1.5deg) skewX(-1deg);
//   -ms-transform: rotate(-1.5deg) skewX(-1deg);
//   transform: rotate(-1.5deg) skewX(-1deg);
// }

// :end

.welcomeBox{
    padding: 2.5rem 0 0 0 ;
    text-align: center;
    h1{
        color: #fff;
    }
    p{
        color: #fff;
    }
    img{
        max-width: 100%;
    }
}

// /ServicesStylez
.ServicesStyle{
    margin: 3rem 0;
    // h3{margin: 1rem 0;}
    
    .serviceBox{
        background: #fff;
        padding: 1rem;
        transition: all .5s;
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        border: #80a8ff 1px solid;
        &::after{
          content: "";
          position: absolute;
          bottom: -20px;
          right: -20px;
          background: #ff000013;
          width: 80px;
          height:80px;
          border-radius: 150px;
        }
        i{font-size: 42px; color: #133381; border: #eee 2px solid; background: #eee; padding: 0.8em; border-radius: 100%; transition: all 0.5s;}
        a{color: var(--bs-body-color);}
        &:hover{
            background: linear-gradient(-45deg, #17284e, #770737, #041b4d, #133381);
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
            a{color:#fff;}
            i{background: #ff9d2c; color:#fff;}
            .dance_hover {
                animation-name: dance_hover;
                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: 1;
              }
        }
        // .detailSr{
        //     -webkit-line-clamp: 2; 
        //    line-clamp: 2; 
        //     -webkit-box-orient: vertical;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        //     display: -webkit-box;
        // }
    }
}





@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
/* Draw Line Animation */
@keyframes start-draw {
  to {
    height: 95%;
  }
}
/* Infinite Rotation Animation */
@keyframes rotate-anim {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
/*Pulse Border Animation*/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}




// start
.services-box .icon {
    width: 80px;
    height: 80px;
    line-height:70px;
    background:linear-gradient(90deg, rgb(0, 140, 255) 0%, rgba(0,55,247,1) 50%, rgb(65, 59, 255) 100%);
    font-size:40px;
    color:#fff;
    -webkit-animation:border-transform 10s linear infinite alternate forwards;
    animation:border-transform 10s linear infinite alternate forwards;
    border-radius:50%;
    -webkit-transition:.5s;
    transition:.5s;
    text-align:center;
    // border:1px solid #3f3f3f;
   margin: auto;
   margin-bottom: 1rem;
   }
 
   @-webkit-keyframes border-transform {
    0%,
    100% {
     border-radius:63% 37% 54% 46%/55% 48% 52% 45%
    }
    14% {
     border-radius:40% 60% 54% 46%/49% 60% 40% 51%
    }
    28% {
     border-radius:54% 46% 38% 62%/49% 70% 30% 51%
    }
    42% {
     border-radius:61% 39% 55% 45%/61% 38% 62% 39%
    }
    56% {
     border-radius:61% 39% 67% 33%/70% 50% 50% 30%
    }
    70% {
     border-radius:50% 50% 34% 66%/56% 68% 32% 44%
    }
    84% {
     border-radius:46% 54% 50% 50%/35% 61% 39% 65%
    }
   }
   @keyframes border-transform {
    0%,
    100% {
     border-radius:63% 37% 54% 46%/55% 48% 52% 45%
    }
    14% {
     border-radius:40% 60% 54% 46%/49% 60% 40% 51%
    }
    28% {
     border-radius:54% 46% 38% 62%/49% 70% 30% 51%
    }
    42% {
     border-radius:61% 39% 55% 45%/61% 38% 62% 39%
    }
    56% {
     border-radius:61% 39% 67% 33%/70% 50% 50% 30%
    }
    70% {
     border-radius:50% 50% 34% 66%/56% 68% 32% 44%
    }
    84% {
     border-radius:46% 54% 50% 50%/35% 61% 39% 65%
    }
   }
// end





.bgBox {
    background: linear-gradient(-45deg, #17284e, #770737, #041b4d, #133381);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}



.OurMission {
    position: relative;
    width: 100%;
    // background: #131f4a;
    background: rgb(3,0,103);
    background: linear-gradient(90deg, rgba(3,0,103,1) 0%, rgba(0,55,247,1) 50%, rgba(3,0,103,1) 100%);
    padding: 1.5rem 0;
    z-index: 1;
    h2{
        color: #f2efdd;
        margin: 0;
        padding-bottom: 1rem;
        
    }
    h5{
        color: #f2efdd;
        margin: 0;font-weight: 200;
    }
    p{
        color: #f2efdd;
    }
    .row{
        align-items: center;
    }
    img{max-width: 100%;}
    .cta-shape {
        position: absolute;
        left: 4%;
        bottom: 0;
        z-index: -1;
        opacity: 0.1;
    }
  }
@media (max-width:768px) {
    .OurMission{
        .cta-shape{display: none;}
    }
}



  
.bganimated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  
  @media (max-width: 991px) {
.bganimated {
      -webkit-animation: none;
      animation: none;
    }
  }

//   up-down
  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
//   up-down

// 484255
.FooterStyle{
    padding: 3.5rem 0 1.5rem 0;
    background:var(--bg2);
    // background-size: 400% 400%;
    // animation: gradient 15s ease infinite;
    h3{
        font-size: 22px;
        color: #fff;
        padding-bottom: 1rem;
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            display: block;
            line-height: 28px;
            font-size: 14px;
            color: #fff;
            a{
                color: #fff;
                &:hover{
                    color: rgb(224, 224, 224);
                }
            }
        }
    }
    .footerb{border-top:1px solid rgba(255, 255, 255, 0.1); padding-top: 1.5rem; margin-top: 3.5rem; color: #fff;}
}

.pageStyle{
  background: #fff;
    .pageBanner{
        // background: #041b4d;
        background: rgb(3,0,103);
        background: linear-gradient(90deg, rgba(3,0,103,1) 0%, rgba(0,55,247,1) 50%, rgba(3,0,103,1) 100%);
        padding: 2rem 0;
        h1{
            color: #fff;
        }
    }
    .pageBody{
        min-height: 65vh;
        background: #fff;
        padding: 1rem;
        margin: 0rem 0;
        text-align: justify;
    }
}
.iframeContact{
    width: 100%;
    min-height: 350px;
    border-bottom: #133381 4px solid;
    border-top: #133381 2px solid;
}
.contactForm{
    border: #eee 1px solid;
    padding: 1.5rem;
    border-radius: 15px;
}
.whatsappIcon{font-size: 15px; display: inline-block;}
.backBtnStyle{position: fixed; cursor: pointer; bottom: 15px; right: 15px; z-index: 9; width: 42px; height: 42px; line-height: 42px; border-radius: 50px; color: #fff !important; background: #0854f8; text-align: center;}
.gal{column-count: 4;}
.gal img {
    width: 100%; padding: 4px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 5px;
    margin-bottom: 15px;
  }
  @media  (max-width:778px) {
    .gal{column-count: 2;}
    .d-none-m{display: none;}
    .mobtext{text-align: center; margin: auto;}
    .FistServicesBx {
      .sBox{
        width: 100%;
        height: auto;
        min-height: auto;
        border-radius: 1em;
      }
    }
    .ServicesStyle{
      .serviceBox{
        text-align: center;
        margin: auto;
        i{margin: auto;}
      }
    } 
  }
  .ClintLogo{
    margin: 0;
    padding: 3rem 0;
    background: #fff;
    h3{
        font-weight: 700;
    }
    .clintbx{
        height: 50px;
        position: relative;
        img{
            height: auto;
            max-height: 100%;
            max-width: 100%;
            width: auto !important;
            position: absolute;
            margin: auto;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            
        }
        &:hover{
            img{
                filter: grayscale(0%);
                cursor: pointer;
            }
        }
    }
  }

  // .lodingBx{ filter: hue-rotate(45deg);}
  .progress{
    height: 8px !important;
    background: #fff !important;
  }
  
.speen{min-height: 100vh; background:rgba(0, 0, 0, 0.5); display: flex; align-items: center; justify-content: center;}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}



.errorStyle{font-size: 12px; color: #f00;}
.carousel-indicators{display: none !important;}
.pointercss{
  cursor: pointer;
}
.SchoolErp{
  .nav-pills{
    gap: 4px;
    .nav-item{
      .nav-link{
        border: #9fbbff 1px solid;
        padding: 4px 10px;
        border-radius: 3px;
        // color: #333;
      }
    }
  }
  .portfolioItem{
    position: relative;
    img{
      width: 100%;
      display: block;
    }
    h5{
      text-align: center;
      font-size: 14px;
      background:#2b2b2b;
      padding: 5px;
      color: #fff;
    }
  }
  .poverlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    .ptext{
      color: white;
      font-size: 1em;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  
  .portfolioItem:hover .poverlay {
    height: 100%;
  }
}
.make-me-sticky {
  position: -webkit-sticky;
	position: sticky;
	top: 0;
}
.formBx{
  background: #0e2258;
  padding: 1.2em;
  border-radius: 0.3em;
  color: #fff;
}
@media  (min-width:768px) {
/*MENU*/
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}
/*END MENU*/
}