@media (max-width:768px) {
    .navbar-collapse.show{
       
    }
    .ServicesStyle{
        .serviceBox{
           i{display: block; width: max-content; margin-bottom: 12px; padding: 0.5em;}
        }
    }
    .FistServicesBx{
        .sBox{
            h3{
                margin: 10px 0;
            }
        }
    }
    .navbar-brand{
        img{
            height: 50px;
        }
    }
    .reactBoxMain {
        .reactStyle {
            h2{
                font-size: 2em;
            }
        }
}
    }